

































































































































































































































































































































































































































import { estructura_basicaDto } from "@/shared/dtos/visualizacion_dieta/base_datos_alimentos/estructura_basicaDto";
import { UtilsNumber } from "@/utils/utils-number";
import { DxGroupItem } from "devextreme-vue/form";
import { Component, Inject, Prop, PropSync, Vue } from "vue-property-decorator";

@Component({
  components: { DxGroupItem },
})
export default class estructura_basica_ficha extends Vue {
  @PropSync("estructura", { default: new estructura_basicaDto() })
  datasource!: estructura_basicaDto;
  @Inject("$validator") $validator!: any;
  @Prop({ default: false }) editable!: boolean;

  created() {
    this.datasource.energia = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.energia).toString()
    );
    this.datasource.etanol = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.etanol).toString()
    );
    this.datasource.glucidos_totales = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.glucidos_totales).toString()
    );
    this.datasource.polisacaridos = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.polisacaridos).toString()
    );
    this.datasource.azucares = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.azucares).toString()
    );
    this.datasource.fibra = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.fibra).toString()
    );
    this.datasource.grasas_totales = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.grasas_totales).toString()
    );
    this.datasource.agmi = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.agmi).toString()
    );
    this.datasource.agpi = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.agpi).toString()
    );
    this.datasource.ags = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.ags).toString()
    );
    this.datasource.colesterol = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.colesterol).toString()
    );
    this.datasource.proteinas_totales = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.proteinas_totales).toString()
    );
    this.datasource.proteinas_animales = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.proteinas_animales).toString()
    );
    this.datasource.proteinas_vegetales = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.proteinas_vegetales).toString()
    );
    this.datasource.iodo = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.iodo).toString()
    );
    this.datasource.sodio = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.sodio).toString()
    );
    this.datasource.potasio = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.potasio).toString()
    );
    this.datasource.calcio = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.calcio).toString()
    );
    this.datasource.magnesio = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.magnesio).toString()
    );
    this.datasource.fosforo = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.fosforo).toString()
    );
    this.datasource.hierro = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.hierro).toString()
    );
    this.datasource.zinc = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.zinc).toString()
    );
    this.datasource.selenio = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.selenio).toString()
    );
    this.datasource.vitamina_b1 = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.vitamina_b1).toString()
    );
    this.datasource.vitamina_b2 = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.vitamina_b2).toString()
    );
    this.datasource.vitamina_b6 = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.vitamina_b6).toString()
    );
    this.datasource.vitamina_b12 = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.vitamina_b12).toString()
    );
    this.datasource.acido_folico = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.acido_folico).toString()
    );
    this.datasource.niacina = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.niacina).toString()
    );
    this.datasource.vitamina_c = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.vitamina_c).toString()
    );
    this.datasource.vitamina_a = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.vitamina_a).toString()
    );
    this.datasource.vitamina_d = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.vitamina_d).toString()
    );
    this.datasource.vitamina_e = Number.parseFloat(
      UtilsNumber.ValueToNumber(this.datasource.vitamina_e).toString()
    );
  }

  public get proteinas_totales() {
    this.datasource.proteinas_totales =
      //@ts-ignore
      Number.parseFloat(this.datasource.proteinas_animales) +
      //@ts-ignore
      Number.parseFloat(this.datasource.proteinas_vegetales);
    return UtilsNumber.RoudTwoDecimals(this.datasource.proteinas_totales);
  }

  public get grasas_totales() {
    this.datasource.grasas_totales =
      //@ts-ignore
      Number.parseFloat(this.datasource.agmi) +
      //@ts-ignore
      Number.parseFloat(this.datasource.agpi) +
      //@ts-ignore
      Number.parseFloat(this.datasource.ags);
    return UtilsNumber.RoudTwoDecimals(this.datasource.grasas_totales);
  }

  public get glucidos_totales() {
    this.datasource.glucidos_totales =
      //@ts-ignore
      Number.parseFloat(this.datasource.polisacaridos) +
      //@ts-ignore
      Number.parseFloat(this.datasource.azucares) +
      //@ts-ignore
      Number.parseFloat(this.datasource.fibra);

    return UtilsNumber.RoudTwoDecimals(this.datasource.glucidos_totales);
  }
}
